.logo-and-title {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.headerTexts {
  line-height: 2rem;
  text-align: center;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 15px;

}