/* resultPage.css */
.headerPart {
  padding-top: 20px;
  display: block;
  color: black;
  border: 1px solid #ddd;
}

/* Base Styles */
.result-page {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcf7f7;
}

.card {
  width: 100%;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  background-color: #fff;
  min-height: 100%;
  box-sizing: border-box;
}

.profile-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  margin-left: 15rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.profile1 {
  align-items: center;
  justify-content: center;
}

.excel-table {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-collapse: collapse;
}

.excel-table table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.excel-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.course-details table {
    margin-top: 10px;
    width: 100%;
  border-collapse: collapse;
}

.student_info table {
  width: 100%;
  border-collapse: collapse;
}

.student_info th,
.student_info td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  padding-left: 10%;
  /* Center align text in table cells */
}

.course-details th,
.course-details td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  /* Center align text in table cells */
}

.remarks {
  background-color: #f0dd9d;
  /* Change this color to your desired background color */
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: bold;

}

.remarks p {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

.bangla_text {
  background-color: #d6eff9;
  /* Change this color to your desired background color */
  padding: 1px;
  border-radius: 5px;
  margin-top: 20px;
  color: #1b5c74;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
}

.button {
  padding: 15px;
  background-color: #2f1b72;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: medium;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header h1 {
    font-size: 1.25rem;
  }

  .header-subtext {
    font-size: 0.875rem;
  }

  th,
  td {
    padding: 4px;
    font-size: 0.875rem;
  }

  .button {
    font-size: 0.875rem;
    padding: 8px 16px;
  }

  .buttons {
    flex-direction: column;
    gap: 8px;
  }

  .profile-image {
    margin-left: 0;
    width: 60px;
    height: 60px;
  }

  .excel-table,
  .course-details {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 8px;
  }

  .header {
    padding: 8px;
  }

  th,
  td {
    font-size: 0.75rem;
  }

  .button {
    font-size: 0.75rem;
    padding: 6px 12px;
  }
}