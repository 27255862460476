/* NavMenu.css */
.nav-menu {
    background-color: rgba(0, 72, 124, 0.795);
    width: 800px;
}

.menu-toggle {
    display: none;
    /* Hide menu toggle button by default */
}

.menu-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.menu-items.active {
    display: flex;
    /* Show menu items when active */
    flex-direction: column;
    position: absolute;
    top: 60px;
    /* Adjust as necessary to avoid overlapping with header */
    left: 0;
    width: 100%;
    background-color: rgba(0, 72, 124, 0.795);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.menu-items li {
    text-align: center;
}

.menu-items li a {
    display: block;
    color: white;
    text-decoration: none;
    padding: 14px 20px;
}

.menu-items li a:hover {
    background-color: #ddd;
    color: black;
}

.selectedNav {
    background: #ffff;
    color: black !important;
}

/* Media query for smaller screens */
@media (max-width: 768px) {

    .nav-menu {
        background-color: rgba(0, 72, 124, 0.795);
        width: 300px;
        margin: auto;
    }

    .nav-menu ul {
        flex-direction: column;
        display: none;
        /* Hide menu items by default */
    }

    .nav-menu ul.active {
        display: flex;
        margin-top: 29%;
        /* Show menu items when active */
    }

    .nav-menu .menu-toggle {
        display: block;
        color: white;
        padding: 14px 20px;
        cursor: pointer;
        text-align: center;
    }

    .nav-menu ul li {
        width: 100%;
        /* Full-width menu items */
    }
}