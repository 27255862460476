.latest-notices-container {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-sizing: border-box;
}

.notices-list {
  list-style-type: none;
  padding: 0;
}

.notice-item {
  text-align: start;
  box-shadow: 5px 5px 5px rgba(0.2, 0.2, 0.2, 0.2);
  padding: 10px;
  margin: 10px;
  display: flex;
}

.notice-icon {
  display: inline-block;
  margin-right: 10px;
  margin-top: 1rem;
}

.notice-content {
  display: inline-block;
  vertical-align: top;
}

.notice-time {
  color: #999;
  margin-top: 5px;
  font-size: smaller;
}

.notice-details {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}