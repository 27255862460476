html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #07151fd3;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  /* Center items horizontally */
}

.header,
.main,
.footer {
  max-width: 800px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.header {
  color: white;
  text-align: center;
  background-color: #003459;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  flex: 1;
  overflow: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center items vertically */
}

.footer {
  color: white;
  background-color: #003459;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2,
h3 {
  padding: 0;
  margin: 0;
}

/* Media queries for responsiveness */
@media (max-width: 600px) {

  .header,
  .main,
  .footer {
    padding: 0px;
  }

  .header {
    font-size: 1em;
    /* Adjust font size */
  }

  .main {
    font-size: 1em;
    /* Adjust font size */
  }

  .footer {
    font-size: 0.8em;
    /* Adjust font size */
  }
}