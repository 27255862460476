.form {
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.form-group {
  padding-bottom: 20px;
  text-align: left;

}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  color: #333;
  padding-bottom: 5px;
  text-align: left;

}

.form-control:hover {
  background-color: #e0e0e0;
  border-color: #007bff;
  padding: 10px;

}

.form-control:focus {
  background-color: #fff;
  border-color: #0056b3;
  outline: none;
  padding: 10px;

}

.form-control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 15px;
  transition: border-color 0.3s ease-in-out;
  padding: 10px;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  flex-direction: column;
  /* Stack buttons vertically by default */
  align-items: center;
  /* Center align the buttons horizontally */
  padding: 10px;
  /* Add padding around the button group */
}

.check-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
  margin: 4%;
  padding: 10px 50px;
}

.check-button {
  background-color: #003459;
  color: #fff;
}

.check-button:hover {
  background-color: rgb(81, 60, 114);
}

.check-button:disabled,
.check-button.disabled {
  background-color: #cccccc;
  /* Grey out disabled button */
  cursor: not-allowed;
  /* Not-allowed cursor for disabled button */
  color: #666666;
  /* Lighten the text color */
}