.fooster {
  color: white;
  background-color: #003459;
  margin: auto;
  text-align: center;
  padding: 10px;
}


.footer-content {
  margin: 2px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}